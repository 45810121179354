import {gridCodes} from "@/enums/gridCodes";

const initState = () => ({
  productCategories: [],
  filteredCategories: [],
  itemsPerPage: 10,
  currentPage: 1,

  currentDetailElements: [],
  detailTableItemsPerPage: 10,
  detailTableCurrentPage: 1,
})

export const state = initState

export const mutations = {
  setProductCategories(state, pCategories) {
    state.productCategories = pCategories
  },
  setFilteredProductCategories(state, filteredCategories) {
    state.filteredCategories = filteredCategories
  },
  setItemsPerPage(state, pItemsPerPage) {
    state.itemsPerPage = pItemsPerPage
  },
  setCurrentPage(state, pCurrentPage) {
    state.currentPage = pCurrentPage
  },
  removeCategory(state, id) {
    state.productCategories = state.productCategories.filter(cat=>cat.id !== id)
  },
  setCurrentDetailElements(state, elements) {
    let transformedElements = []
    transformedElements = elements.map(item=> item =
      {
        isChecked : false,
        name: item.name,
        code: item.code,
        id: item.id,
        imageCode: item.binaryContentCode,
        rank: item.rank
    })
    state.currentDetailElements = transformedElements;
  },
  setDetailTableItemsPerPage(state, pItemsPerPage) {
    state.detailTableItemsPerPage = pItemsPerPage
  },
  setDetailTableCurrentPage(state, pCurrentPage) {
    state.detailTableCurrentPage = pCurrentPage
  }
}

export const actions = {
  getProductCatalogColumns() {
    this.$axios.$get(`GridSetting/${gridCodes.ProductCatalog}`).then(v=>{
    })
  },

  fetchProductCategories({commit}) {
        return this.$axios.$get('ProductCategories?getOnlyParent=false&ignoreSettingUsersCanOnlyViewTheirItemTypes=true').then(v=>{
          commit('setProductCategories', v)
          commit('setFilteredProductCategories', v)
        })
  },

  async saveProductCatalogColumnSettings({commit}, columnSettings) {
    return await this.$axios.$post('GridSetting', columnSettings)
  },
  updateItemsPerPage({commit}, {itemsPerPage, currentPage}) {
    commit('setItemsPerPage', itemsPerPage)
    commit('setCurrentPage', currentPage)
  },
  updateDetailItemsPerPage({commit}, {itemsPerPage, currentPage}) {
    commit('setDetailTableItemsPerPage', itemsPerPage)
    commit('setDetailTableCurrentPage', currentPage)
  },
   filterProductCategories({commit, state, dispatch}, filters) {
    let filtersEmpty = true
    let filteredCategories = []

      if(filters.nameSearchText.trim().length) {
        filtersEmpty = false
        filteredCategories = state.productCategories.filter(cat=>cat.name.toLowerCase().includes(filters.nameSearchText.toLowerCase()))
      }
      if(filters.codeSearchText.trim().length) {
        if(filtersEmpty) {
          filteredCategories = state.productCategories
        }
        filtersEmpty = false
        filteredCategories = filteredCategories.filter(cat=>cat.code.toLowerCase().includes(filters.codeSearchText.toLowerCase()))
      }
      if(filters.parentSearchSelected) {
        if(filtersEmpty) {
          filteredCategories = state.productCategories
        }
        filtersEmpty = false
        filteredCategories = filteredCategories.filter(cat=>cat.parentID===filters.parentSearchSelected.id)
      }
      if(filtersEmpty) {
        commit('setFilteredProductCategories', state.productCategories)
      }
      else {
        commit('setFilteredProductCategories', filteredCategories)
      }
  },

  async uploadProductCategory({commit}, {productCategoryToSave}) {
    const formData = new FormData()
    formData.append('name', productCategoryToSave.name)
    formData.append('code', productCategoryToSave.code)
    if(productCategoryToSave.parentID !== null && productCategoryToSave.parentID !== undefined) {
      formData.append('parentId', productCategoryToSave.parentID)
    }
    if(productCategoryToSave.images.largeImage !== null) {
      formData.append('largeImage',  productCategoryToSave.images.largeImage.blob, productCategoryToSave.images.largeImage.filename)
    }
    if(productCategoryToSave.images.smallImage !== null) {
      formData.append('smallImage',  productCategoryToSave.images.smallImage.blob, productCategoryToSave.images.smallImage.filename)
    }
    if(productCategoryToSave.images.title1Image !== null) {
      formData.append('title1Image',  productCategoryToSave.images.title1Image.blob, productCategoryToSave.images.title1Image.filename)
    }
    if(productCategoryToSave.images.title2Image !== null) {
      formData.append('title2Image',  productCategoryToSave.images.title2Image.blob, productCategoryToSave.images.title2Image.filename)
    }

    return this.$axios.$post('/ProductCategories/create', formData).then(()=>{
      this.$toast.success(
        this.$i18n.t('saved_successfully').replace('{name}', productCategoryToSave.name)
      )
    })
  },

  async updateProductCategory({commit}, {productCategoryToSave}) {
    const formData = new FormData()
    formData.append('name', productCategoryToSave.name)
    formData.append('code', productCategoryToSave.code)
    formData.append('rank', productCategoryToSave.rank)
    formData.append('description', productCategoryToSave.description)

    productCategoryToSave.items.map((item, index) => {
      formData.append(`items[${index}].id`, item.id)
    })

    if(productCategoryToSave.parentId !== null && productCategoryToSave.parentId !== undefined) {
      formData.append('parentId', productCategoryToSave.parentId)
    }
    if(productCategoryToSave.images.largeImg !== null) {
      formData.append('largeImage',  productCategoryToSave.images.largeImg.blob, productCategoryToSave.images.largeImg.filename)
    }
    if(productCategoryToSave.images.smallImg !== null) {
      formData.append('smallImage',  productCategoryToSave.images.smallImg.blob, productCategoryToSave.images.smallImg.filename)
    }
    if(productCategoryToSave.images.title1Img !== null) {
      formData.append('title1Image',  productCategoryToSave.images.title1Img.blob, productCategoryToSave.images.title1Img.filename)
    }
    if(productCategoryToSave.images.title2Img !== null) {
      formData.append('title2Image',  productCategoryToSave.images.title2Img.blob, productCategoryToSave.images.title2Img.filename)
    }

    return this.$axios.$post(`/ProductCategories/update/${productCategoryToSave.id}`, formData).then(()=>{
      this.$toast.success(
        this.$i18n.t('saved_successfully').replace('{name}', productCategoryToSave.name)
      )
      this.$router.push('/backoffice/product-catalog')
    })
  },

  deleteProductCategory({commit, dispatch},productCategory) {
    return new Promise(()=> {
      return this.$axios.$delete('/ProductCategories/delete', {data: productCategory})
    }).then(
      commit('removeCategory', productCategory.id),
      dispatch('filterProductCategories',
        {
          nameSearchText: '',
          codeSearchText: '',
          parentSearchSelected: null,
      }),
      this.$toast.success(
        this.$i18n.t('deleted_successfully').replace('{name}', productCategory.name)
      )
    )
  },

  async fetchCategoryFromId({commit}, id) {

      return await this.$axios.$get(`/ProductCategories/${id}`)
  },


  fetchCategoryElementsFromId({commit}, {id, pageNo}) {
    this.$axios.$get(`/ProductCategories/categoryItems/${id}`).then(results  => {
      commit('setCurrentDetailElements', results?.value)
    })
  },
  fetchAllItemTypes({commit}) {
    return this.$axios.$get(`/ItemTypes/CanBeAddedToCategory`)
  },
  addItemTypesToProductCategory({commit}, {itemsIds, pcId}) {
    return this.$axios.$post(`/ItemTypes/AddItemsToProductCategory/${pcId}`, itemsIds)
  },
  removeItemTypesFromProductCategory({commit}, {itemIds, pcId}) {
    return this.$axios.$post(`/ItemTypes/RemoveItemsFromProductCategory/${pcId}`, itemIds)
  }
}
