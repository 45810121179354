const initState = () => ({
})

export const state = initState

export const actions = {
  async getRoleTypesByCode({commit}, {rolTyeCode}){
    return await this.$axios.$post(
      `/user/get-role-by-code/${rolTyeCode}`,
    )
  },
}
