import {formActions} from '@/enums/formActions'

const initState = () => ({
  priceQuotes: [],
  page: 1,
  totalQuotes: 0,
  options: {},
})

export const state = initState

export const mutations = {
  setPriceQuotes(state, {priceQuotes}) {
    state.priceQuotes = priceQuotes
  },
  setTotalQuotes(state, {totalQuotes}) {
    state.totalQuotes = totalQuotes
  },
  setOptions(state, {options}) {
    state.options = options
  },
  removeApprovedQoutes(state,id){
    state.priceQuotes = state.priceQuotes.filter(item => item.ID !==id)
  },
  incressePirceQouteNotCount(state,id){
    let objIndex = state.priceQuotes.findIndex(obj => obj.ID === id)

    state.priceQuotes[objIndex].noteCount++
  },

}

export const actions = {
  async fetchPriceQuotes({commit, state}, {filters}) {
    const {page, itemsPerPage} = state.options
    const result = await this.$axios.$get(
      '/PriceQuotes?pageSize=' + itemsPerPage + '&pageNo=' + page + filters
    )
    commit('setPriceQuotes', {priceQuotes: result.items})
    commit('setTotalQuotes', {totalQuotes: result.pagingData.itemCount})

    return result
  },
  async setPriceQuoteStatus({state, commit, dispatch}, {actions}) {

    const result = await this.$axios.$post('/PriceQuotes/set-status', {
      id: actions.id,
      statusChangeAction: actions.status,
      statusCode: actions.status
    })
    if (!result.hasErrors) {
      this.$toast.success(result.successMessage)
      commit('removeApprovedQoutes',actions.id)
    } else {
      this.$toast.error(result.errorMessages[0].value)
    }
  },


}
