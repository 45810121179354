import {formActions} from '@/enums/formActions'

const initState = () => ({})

export const state = initState

export const getters = {}

export const mutations = {}

export const actions = {
  async saveLimitation({commit}, {limitations}) {
    await this.$catch(
      async () => {
        await this.$axios.$post(`/assetslimitation`, limitations)
      },
      formActions.Save,
      limitations.assetID
    )
  },
  async updateLimitation({commit}, {limitations, assetId}) {
    await this.$catch(
      async () => {
        await this.$axios.$post(`/assetslimitation/update/${assetId}`, limitations)
      },
      formActions.Save,
    )
  },
  async fetchLimitationsByAssetId({commit}, {assetId}){
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const limitations = await this.$axios.$get(`/assetslimitation/${assetId}`)
          resolve({limitations})
        },
        formActions.Load,
        'limitations'
      )
    })
  },
}
