const checkoutAddressesInitState = () => ({
  shippingAddress: null,
  billingAddress: null,
  sellToAddress: null,
  useDifferentShippingAddress: false,
  shipToSellToAddress: false,
  customerID: 0,
  notes: '',
  pONumber: '',
  customerUpdatedWhen: '',
  shippingMethodID: null,
  fastThouchShippingMethodID: null,
  customerWoNumber: ''
})

const initState = () => ({
  checkoutAddresses: checkoutAddressesInitState(),
})

export const state = initState

export const getters = {
  getCheckoutAddresses: (state) => () => {
    return state.checkoutAddresses
  },
}

export const mutations = {
  setCheckoutAddresses(state, {checkoutAddresses}) {
    state.checkoutAddresses = checkoutAddresses
  },
  setNotes(state, notes) {
    state.notes = notes
  },
  setCheckoutAddressNotes(state, notes) {
    state.checkoutAddresses.notes = notes
  },
  resetCheckoutAddresses(state) {
    state.checkoutAddresses = checkoutAddressesInitState()
  },
}

export const actions = {
  async fetchCustomerAddresses({commit}) {
    const addresses = await this.$axios.$get('/customers/getCustomerAddresses')
    commit('setCheckoutAddresses', {checkoutAddresses: addresses})
  },
}
