import {formActions} from '@/enums/formActions'

const initState = () => ({
  contents: [],
  page: 1,
  itemsPerPage: 10,
})

export const state = initState

export const getters = {
  getContents: (state) => (searchStr) => {
    const regexp = new RegExp(searchStr, 'i')
    return state.contents.filter(
      (x) => regexp.test(x.name) || regexp.test(x.slug)
    )
  }
}

export const mutations = {
  setContents(state, {contents}) {
    state.contents = contents
  },
  setPage(state, {page}) {
    state.page = page
  },
  setItemsPerPage(state, {itemsPerPage}) {
    state.itemsPerPage = itemsPerPage
  }
}

export const actions = {
  fetchContents({commit, state}, filters) {
    const result = new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const contents = await this.$axios.$get('/manage/contents')
          resolve({contents})
        },
        formActions.Load,
        'contents'
      )
    })
    return result
  },
  async fetchContentsAndUpdateTable({commit, state}, filters) {
    const contents = await this.$axios.$get('/manage/contents')
    commit('setContents', {contents: contents})
    try {
      if(filters !== undefined && filters.filters.isFiltered) {
        commit('setContents', {contents: contents})
        let filteredContents = []
        if(filters.filters.items[0].filterType === 1) {
          filters.filters.items.forEach((f)=>{
            filteredContents.push(state.contents.filter(item=>item.name === f.name)[0])
          })
        }
        else {
          filters.filters.items.forEach((f)=> {
            filteredContents.push(...state.contents.filter(item=>item.contentTypeID === f.typeID))
          })
        }
        commit('setContents', {contents: filteredContents})
      }
      else {
        commit('setContents', {contents: contents})
      }
    }catch(e) {}
  },

  async deleteContentById({commit, state}, id) {
    await this.$axios.$delete(`manage/contents/${id}`)
  },
  fetchContentById({commit}, {id}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const content = await this.$axios.$get(`/manage/contents/${id}`)
          resolve({content})
        },
        formActions.Load,
        'content'
      )
    })
  },
  fetchContentFieldsById({commit}, {contentId}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const fields = await this.$axios.$get(
            `/manage/contents/${contentId}/fields`
          )
          resolve({fields})
        },
        formActions.Load,
        'fields'
      )
    })
  },

  saveContent({commit}, {content, value}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const returnedContent = await this.$axios.$post(
            `/manage/contents`,
            content
          )
          resolve({content: returnedContent})
          // commit('setContent', {content: content})
          this.$toast.success(
            this.$i18n.t('saved_successfully').replace('{name}', content.name)
          )
        },
        formActions.Save,
        content.name
      )
    })
  },
  saveContentWithFields({commit}, {content, value}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const returnedContent = await this.$axios.$post(
            `/manage/contents/content-with-fields`,
            content
          )
          resolve({content: returnedContent})
          // commit('setContent', {content: content})
          this.$toast.success(
            this.$i18n.t('saved_successfully').replace('{name}', content.content.name)
          )
        },
        formActions.Save,
        content.name
      )
    })
  },
  updateStateOptions({commit}, {options}) {
    commit('setPage', {page: options.page})
    commit('setItemsPerPage', {itemsPerPage: options.itemsPerPage})
  }
}
