import {formActions} from '@/enums/formActions'

const initState = () => ({
  components: [],
  filteredComponents: [],
})

export const state = initState

export const getters = {
  getComponents: (state) => (searchStr) => {
    const regexp = new RegExp(searchStr, 'i')
    return state.components.filter(
      (x) => regexp.test(x.name) || regexp.test(x.code)
    )
  },
}

export const mutations = {
  setComponents(state, {components}) {
    state.components = components
  },
  setFilteredComponents(state, {filters}) {
    if(filters === undefined || filters.length === 0) {
      state.filteredComponents = state.components
    }
    else {
      if(filters.filters.filterType === 1) {
        // filter by individual component names
        let results = []
        filters.filters.selectedFilters.forEach(f=>{
          results.push(state.components.filter(comp=>comp.name === f)[0])
        })
          state.filteredComponents = results
      }
      else if(filters.filters.filterType === 2) {
        // filter by contentType and nestable
        let results = []
        if(filters.filters.selectedFilters[0] === 'Content type') {
          results = state.components.filter(comp=>comp.contentType === true)
          if(filters.filters.selectedFilters.length > 1) {
            results = results.filter(comp=>comp.nestable === true)
          }
        }
        else {
          results = state.components.filter(comp=>comp.nestable === true)
          if(filters.filters.selectedFilters.length > 1) {
            results = results.filter(comp=>comp.contentType === true)
          }
        }

        state.filteredComponents = results
      }
      if(filters.filters.selectedFilters.length === 0) {
        state.filteredComponents = state.components
      }
    }
  }
}

export const actions = {
  fetchComponents({commit, state}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const components = await this.$axios.$get('/manage/components')
          commit('setComponents', {components})
          resolve({components})
        },
        formActions.Load,
        'components'
      )
    })
  },
  filterComponents({commit, state}, filters) {
    commit('setFilteredComponents', {filters: filters})
  },
  fetchComponentById({commit}, {componentId}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const component = await this.$axios.$get(
            `/manage/components/${componentId}`
          )
          resolve({component})
        },
        formActions.Load,
        'component'
      )
    })
  },
  fetchComponentFieldsById({commit}, {componentId}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const fields = await this.$axios.$get(
            `/manage/components/${componentId}/fields`
          )
          resolve({fields})
        },
        formActions.Load,
        'fields'
      )
    })
  },

  saveComponent({commit, dispatch}, {component, value}) {
    let result = new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          await this.$axios.$post(`/manage/components`, component)
          this.$toast.success(
            this.$i18n.t('saved_successfully').replace('{name}', component.name)
          )
          resolve()
        },
        formActions.Save,
        component.name
      )
    })
    return result
  },
  removeFieldsFromComponent({commit, dispatch}, {component, value}) {
    let result = new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          await this.$axios.$post(`/manage/components`, component)
          resolve()
        },
      )
    })
    return result
  },
  async deleteComponentById({commit, state}, {comp}) {
    let result = new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          await this.$axios.$delete(`/manage/components/${comp.id}`)
          this.$toast.success(
            this.$i18n.t('deleted_successfully').replace('{name}', comp.name)
          )
          resolve()
        },
        formActions.Delete,
        comp.name
      )
    })
    return result
  }
}
