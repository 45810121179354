import {formActions} from '@/enums/formActions'

const initState = () => ({
  countries: [],
  statesByCountry: [],
  allStates: [],
  selectedState: '',
})

export const state = initState

export const getters = {
  getCountry: (state) => (countryId) => {
    return state.countries.find((x) => x.value === countryId)
  },
  getState: (state) => (stateId) => {
    return state.allStates.find((x) => x.value === stateId)
  },
  getCountries: (state) => () => {
    return state.countries
  },
  getStatesByCountry: (state) => () => {
    return state.statesByCountry
  },
  getStatesByCountryId: (state) => (countryId) => {
    return state.allStates.filter((s) => s.parentId === countryId)
  },
}

export const mutations = {
  setCountries(state, {countries}) {
    state.countries = countries
  },
  setStatesByCountryId(state, {object}) {
    state.statesByCountry = state.allStates.filter(
      (currentState) => currentState.parentId === object.value
    )
    console.log(state.statesByCountry)
    state.selectedState = ''
  },
  setAllStates(state, {allStates}) {
    state.allStates = allStates
  },
}

export const actions = {
  fetchCountries({commit}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const countries = await this.$axios.$get('Countries')
          const apiCountries = countries.map((element) => {
            return {text: element.name, value: element.id, code: element.code}
          })
          commit('setCountries', {
            countries: apiCountries,
          })
          resolve(apiCountries)
        },
        formActions.Load,
        'countries'
      )
    })
  },

  fetchAllStates({commit}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const states = await this.$axios.$get('Countries/states')
          const apiStates = states.map((element) => {
            return {
              text: element.name,
              value: element.id,
              parentId: element.parentID,
            }
          })
          commit('setAllStates', {
            allStates: apiStates,
          })
          resolve(apiStates)
        },
        formActions.Load,
        'states'
      )
    })
  },
}
