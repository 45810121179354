const initState = () => ({
  loadSettings: true,
})

export const state = initState

export const getters = {
  getLoadSettingsProperty: (state) => state.loadSettings,
}

export const mutations = {
  setLoadSettingsProperty(state, {loadSettings}) {
    state.loadSettings = loadSettings
  },
}

export const actions = {
  async loadingCache({dispatch, commit}) {
    await dispatch(
      'common/entityStatus/fetchClosedQuoteStatuses',
      {},
      {root: true}
    )
    await dispatch(
      'common/entityStatus/fetchOpenQuoteStatuses',
      {},
      {root: true}
    )
    await dispatch('common/itemType/fetchItemTypes', {}, {root: true})
    await dispatch('backoffice/settings/fetchFields', {}, {root: true})
    await dispatch('common/itemType/fetchMetals', {}, {root: true})
    await dispatch(
      'common/entityStatus/fetchSalesOrderStatuses',
      {},
      {root: true}
    )
    await dispatch(
      'common/entityStatus/fetchJobOrderStatuses',
      {},
      {root: true}
    )
    await dispatch('backoffice/settings/fetchAdminSettings', {}, {root: true})
    await dispatch('common/formatter/fetchFormatSettings', {}, {root: true})
    await dispatch(
      'common/crm/territorialUnit/fetchCountries',
      {},
      {root: true}
    )
    await dispatch(
      'common/crm/territorialUnit/fetchAllStates',
      {},
      {root: true}
    )

    // if (this.$hasPermission('CP_PAYMENTS:VIEWP')) {
    await dispatch(
      'backoffice/payments/creditCard/fetchCreditCards',
      {},
      {root: true}
    )
    // }
    await dispatch(
      'backoffice/payments/creditCard/fetchCreditCardTypes',
      {},
      {root: true}
    )
    await dispatch('common/userProfile/getUserDetails', {}, {root: true})
    await dispatch('common/userProfile/fetchAvailableModules', {}, {root: true})

    commit('setLoadSettingsProperty', {loadSettings: false})
  },
}
