import {formActions} from '@/enums/formActions'

const initState = () => ({
  assetTypes: [],
})

export const state = initState

export const getters = {}

export const mutations = {

  setAssetTypes(state, {assetTypes}) {
    state.assetTypes = assetTypes
  },
}

export const actions = {
  fetchAssetById({commit}, {id}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const asset = await this.$axios.$get(`/manage/assets/${id}`)
          resolve({asset})
        },
        formActions.Load,
        'asset'
      )
    })
  },
  fetchAssetFolders({commit}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const assetFolders = await this.$axios.$get(`/manage/assets/folders`)
          resolve({assetFolders})
        },
        formActions.Load,
        'assetFolders'
      )
    })
  },async deleteFolders({commit}, {id}){
    await this.$catch(
      async () => {
        await this.$axios.$post(`/manage/assets/folder/${id}`)
        this.$toast.success(
          this.$i18n.t('deleted_successfully').replace('{name}', 'Asset')
        )
      },
      formActions.Save,
    )
  },
  async saveAsset({commit}, {formData, fileName}){
    return new Promise((resolve, reject) => {
       this.$catch(
        async () => {
          const asset = await this.$axios.$post(`/upload/asset`, formData, {
            headers: {'content-type': 'multipart/form-data'},
          })
          this.$toast.success(
            this.$i18n.t('saved_successfully').replace('{name}', fileName)
          )
          resolve({asset})
        },
        formActions.Save,
         'asset'
      )
    })
  },
  async updateAsset({commit}, {asset}) {
    return new Promise((resolve, reject) =>{
        this.$catch(
        async () => {
          const updatedAsset = await this.$axios.$post(`/manage/assets/update`, asset)
          resolve({updatedAsset})
        },
        formActions.Save,
          'updatedAsset'
      )
    })

  },
   async downloadAsset({commit}, {id, title}) {
    return  this.$axios({
       method: 'get',
       url: `/manage/assets/download/${id}`,
       responseType: 'arraybuffer',
     })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => console.log('error occured'))
  },
  async getAsset({commit},{id, isPreview}){
    return  this.$axios({
      method: 'get',
      url: `/manage/assets/download/${id}`,
      responseType: 'arraybuffer',
      params: {
        isPreview: isPreview,
      },
    })
  },
  async deleteAssets({commit}, {assets}){
    await this.$catch(
      async () => {
        await this.$axios.$post(`/manage/assets/delete`, assets)
        this.$toast.success(
          this.$i18n.t('deleted successfully').replace('{name}', 'Assets')
        )
      },
      formActions.Save,
    )
  },
  async fetchAssetTypes({commit}) {
    const assetTypes = await this.$axios.$get(`/manage/assets/types`)
    commit('setAssetTypes', {assetTypes})
  },
  fetchAssets({commit}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const assets = await this.$axios.$get(`/manage/assets`)
          resolve({assets})
        },
        formActions.Load,
        'assets'
      )
    })
  },
  async saveFolder({commit}, {folder}) {
    await this.$catch(
      async () => {
        await this.$axios.$post(`/manage/assets/folder`, folder)
        this.$toast.success(
          this.$i18n.t('saved_successfully').replace('{name}', folder.name)
        )
      },
      formActions.Save,
      folder.name
    )
  },
  async changeAssetFolder({commit},{folderID, assets}){
    await this.$catch(
      async () =>{
        await this.$axios.$post(`/manage/assets/folder/change/${folderID}`,assets)
        this.$toast.success(
          this.$i18n.t('saved_successfully').replace('{name}', "Assets")
        )
      },
      formActions.Save
    )
  }
}
