import {PIROEntityTypes} from '@/enums/PIROEntityTypes'

const initState = () => ({
  customOrders: [],
  customOrder: {},
  images: [],
  bigImageURL: '',
  successMessage: '',
  errorMessage: '',
})

export const state = initState

export const getters = {
  customOrderHasMetalSubItem(state) {
    return state.customOrder && state.customOrder.subItems
      ? state.customOrder.subItems.some(
          (s) => s.attachedEntityTypeId === PIROEntityTypes.MasterAlloys
        )
      : false
  },
  subItems(state) {
    return state.customOrder.subItems
  },
  variants(state) {
    return state.customOrder.variants
  },
}

export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  setCustomOrders(state, {customOrders}) {
    state.customOrders = customOrders
  },
  setCustomOrder(state, {customOrder}) {
    state.customOrder = customOrder
  },
  addImage(state, {image}) {
    state.images.push(image)
  },
  deleteImage(state, {image}) {
    if (state.images.length > 1) {
      state.images = state.images.filter((i) => i.file.name !== image.file.name)
      if (state.bigImageURL === image.URL) {
        state.bigImageURL = state.images[0].URL
      }
    } else {
      state.images = []
      state.bigImageURL = ''
    }
  },
  clearImages(state) {
    state.images = []
  },
  setBigImageURL(state, url) {
    state.bigImageURL = url
  },
  setSuccessMessage(state, message) {
    state.successMessage = message
  },
}

export const actions = {
  async getCustomOrders({commit}) {
    const customOrders = await this.$axios.$get('/customOrders')
    commit('setCustomOrders', {customOrders})
  },

  async getTopLevelCustomOrders({commit}) {
    const customOrders = await this.$axios.$get(
      '/customOrders/getTopLevelCustomOrders'
    )
    commit('setCustomOrders', {customOrders})
  },

  async getCustomOrdersByParentId({commit}, parentId) {
    const customOrders = await this.$axios.$get(
      `/customOrders/getByParentId/${parentId}`
    )
    commit('setCustomOrders', {customOrders})
  },

  async getCustomOrderById(
    {commit},
    {id, includeSubItems = false, includeVariants = false}
  ) {
    commit('reset')
    const customOrder = await this.$axios.$get(
      `/customOrders/${id}?includeSubItems=${includeSubItems}&includeVariants=${includeVariants}`
    )
    commit('setCustomOrder', {customOrder})
  },
  async createOrder({state, commit}, formData) {
    if (!formData) return false
    const orderResult = await this.$axios.$post(
      `/customOrders/createOrder`,
      formData
    )
    if (!orderResult.hasErrors) {
      this.$toast.success(orderResult.successMessage)
      if (state.images.length > 0) {
        const formData = new FormData()
        formData.append('orderId', orderResult.value.id)
        state.images.map((img, index) => {
          formData.append(`files[${index}]`, img.file, img.file.name)
        })
        await this.$axios.$post(
          `/customOrders/generateImagesForExistingOrder`,
          formData
        )
      }
    } else {
      this.$toast.error(
        'Something went wrong please contact the site administrator!'
      )
    }
    return orderResult
  },
}
