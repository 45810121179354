const initState = () => ({
  hardcodedValues: [],
})

export const state = initState

export const getters = {
  getHardcodedValues: (state) => (code) => {
    const result = state.hardcodedValues.find(
      (element) => element.code === code.code
    )
    return result?.value
  },
  getOwnerCompanyHardcodedValue: (state) => () => {
    return state.hardcodedValues.find(
      (element) => element.code === 'OWNER_COMPANY_CODE'
    )
  },
}

export const mutations = {
  setHardcodedValues(state, {hardcodedValues}) {
    state.hardcodedValues = hardcodedValues
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  fetchHardcodedValues({commit}) {
    return this.$axios
      .$get('/HardcodedSettings/api/timekeeping/hardcodedsettings')
      .then((values) => {
        commit('setHardcodedValues', {
          hardcodedValues: values.map((element) => {
            return {code: element.code, value: element.val_Str}
          }),
        })
      })
  },
}
