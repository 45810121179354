import {formActions} from "@/enums/formActions";

const initState = () => ({
  wishListItemCount: 0,
  wishListItems: []
})

export  const  state = initState

export const getters = {
  getWishListCount(state){
    return state.wishListItemCount;
  }
}

export const mutations = {
  setWishListItems(state, {wishListItems}) {
    state.wishListItems = wishListItems
  },
  setWishListItemCount(state){
    state.wishListItemCount = state.wishListItems.length;
  }
}

export const actions = {
  getWishListItems({commit}){
    this.$catch(
      async () => {
        const wishListItems = await this.$axios.$get('/wishlists/wishListItems');
        commit('setWishListItems', {wishListItems})
        commit('setWishListItemCount')
      },
      formActions.Load,
      'loadWishListItems'
    )
  },
  async addToWishlist({commit, dispatch}, {shoppingCartItem, path}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const addToWishlistResult = await this.$axios.$post(
            '/wishlists/addtowishlist',
            shoppingCartItem
          )
          if (addToWishlistResult.hasErrors === false) {
            dispatch('getWishListItems')
            commit('setWishListItemCount')
          }
          resolve(addToWishlistResult)
        },
        formActions.Save,
        'addToWishList'
      )
    })
  },
  async deleteWishlistItem({commit, dispatch}, {wishListItemId}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const deleteWishlistItemResult = await this.$axios.$delete(
            `/wishlists/wishlist-item/${wishListItemId}`
          )
          if (deleteWishlistItemResult.hasErrors === false) {
            dispatch('getWishListItems')
            commit('setWishListItemCount')
          }
          resolve(deleteWishlistItemResult)
        },
        formActions.Delete,
        'deleteWishListItem'
      )
    })
  },
}
