const initState = () => ({
  salesOrders: [],
  page: 1,
  totalItems: 0,
  options: {},
  salesOrderItems: [],
})

export const state = initState

export const mutations = {
  setSalesOrders(state, {salesOrders}) {
    state.salesOrders = salesOrders
  },
  setOptions(state, {options}) {
    state.options = options
  },
  setTotalItems(state, {totalOrders}) {
    state.totalItems = totalOrders
  },
  setSalesOrderItems(state, {salesOrderItems}) {
    state.salesOrderItems = salesOrderItems
  },
}

export const actions = {
  async fetchSalesOrders({commit, state}, {filters}) {
    const {page, itemsPerPage} = state.options
    let url = `/SalesOrders?pageSize=${itemsPerPage ? itemsPerPage : 10}&pageNo=${ page ? page : 1}${filters}`
    const result = await this.$axios.$get(url)
    commit('setSalesOrders', {salesOrders: result.items})
    commit('setTotalItems', {totalOrders: result.pagingData.itemCount})

    return result
  },
  async fetchSalesOrderItems({commit, state}, {salesOrderId}) {
    return await this.$axios
      .$get('/SalesOrders/' + salesOrderId + '/items')
      .then((result) => {
        commit('setSalesOrderItems', {salesOrderItems: result})
      })
  },
  async generatePaidInvoice({commit}, id) {
    return await this.$axios.$post(`/SalesOrders/${id}/generate-paid-invoice`)
  }
}
