import {formActions} from '@/enums/formActions'

const initState = () => ({
  modules: [],
})

export const state = initState

export const getters = {
  getModules: (state) => (searchStr) => {
    const regexp = new RegExp(searchStr, 'i')
    return state.modules.filter(
      (x) => regexp.test(x.name) || regexp.test(x.code)
    )
  },
}

export const mutations = {
  setModules(state, {modules}) {
    state.modules = modules
  },
}

export const actions = {
  fetchModules({commit, state}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const modules = await this.$axios.$get('/modules/cp')
          // commit('setModules', {modules})
          resolve({modules})
        },
        formActions.Load,
        'modules'
      )
    })
  },

  async updateRoles({commit}, {roles, value}) {
    await this.$catch(
      async () => {
        await this.$axios.$post(`/modules/cp/roles`, roles.roleSettings)
        this.$toast.success('Successfully saved role settings.')
      },
      formActions.Save,
      'Role settings'
    )
  },
  saveModule({commit}, {module, value}) {
    this.$catch(
      async () => {
        await this.$axios.$post(`/modules/cp`, module)
        commit('setModule', {module})
        this.$toast.success(
          this.$i18n.t('saved_successfully').replace('{name}', module.name)
        )
      },
      formActions.Save,
      module.name
    )
  },
  async postFieldSettings({commit}, {fieldSettings}) {
    await this.$catch(
      async () => {
        const response = await this.$axios.$post(`/modules/cp/fieldsSettings`, fieldSettings)
        if (response.hasErrors === true) {
          response.serverErrors.forEach((error) => {
            this.$toast.error(error)
          })
          return
        }
        this.$toast.success(
          this.$i18n.t('saved_successfully').replace('{name}', 'Field settings')
        )
      },
      formActions.Save,
      'Field settings'
    )
  },
  async fetchModuleByCode({commit}, {moduleCode}) {
    let module = {}
    try{
      let result = await this.$axios.get(`/modules/get-by-code/${moduleCode}`);
      if (result && !result.data.hasErrors) {
        module = result.data.value;
      }
      else {
        this.$toast.error('Error occured while getting the module name');
      }
    }
    catch{
      this.$toast.error('Error occured while getting the module name');
    }

    return module;
  }
}
