//
//
//
//
//
//
//
//

import {mapMutations} from 'vuex'

export default {
  layout(context) {
    const hasFrontendLayout = context.store.getters[
      'common/error/getHasFrontendLayout'
    ].call()
    const currentLayout = hasFrontendLayout
      ? 'frontend/error-layout'
      : 'default'

    return currentLayout
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dynamicComponent: undefined,
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
    }
  },
  head() {
    let title = 'Error'
    if (this.error) {
      title =
        this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    }
    return {
      title,
    }
  },
  mounted() {
    const frontendLayoutExists = this.componentExists('FrontendLayoutError')
    this.setHasFrontendLayout(frontendLayoutExists)
    let lErrorPage = ''
    if (frontendLayoutExists) {
      lErrorPage = 'frontend/error-page-content.vue'
    } else {
      lErrorPage = 'default-error-page'
    }
    try {
      this.dynamicComponent = () => import(`./${lErrorPage}`)
    } catch (e) {
      this.dynamicComponent = () => import(`./default-error-page`)
    }
  },
  methods: {
    ...mapMutations('common/error', ['setHasFrontendLayout']),
    loadedComponents() {
      const loaded = []
      const components = this.$options.components
      for (const key in components) {
        loaded.push(key)
      }
      return loaded
    },
    componentExists(component) {
      const components = this.loadedComponents()
      return components.includes(component)
    },
  },
}
