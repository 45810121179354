import {formActions} from "@/enums/formActions";
import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  paymentProcessor:{
    paymentGatewayPartnerName: null,
  },
})

export const state = initState

export const mutations = {
  setPaymentProcessor(state, {paymentProcessor}){
    state.paymentProcessor = paymentProcessor;
  }
}

export const getters = {
  getPaymentGatewayPartnerName(state){
    return state.paymentProcessor.name;
  },
  getPaymentProcessorConfiguration(state){
    return state.paymentProcessor;
  },
  getPaymentProcessorGatewayId(state){
    return state.paymentProcessor.paymentGatewayId;
  }
}

export const actions = {
  async fetchPaymentProcessor({commit}){
    try {
      let result = await this.$axios.get(`/payment-options/get-by-config`);
      if (result?.data?.apiMessages?.hasErrors && result.apiMessages.serverErrors.length > 0) {
        this.$toast.error('Error while getting payment processor');
      }
      else {
        commit('setPaymentProcessor', {paymentProcessor: result.data.value})
        return result.data.value
      }
    }catch (e) {
      if (e.response) {
        this.$toast.error(e.response.data.Message);
      }  else {
        this.$toast.error(e);
      }
    }
    return null;
  },
}
