import {formActions} from '@/enums/formActions'

const initState = () => ({
  itemGroups: [],
  customOrderItemGroups: [],
  itemTypes: [],
})

export const state = initState

export const getters = {
  getCustomerOrderSettings: (state) => () => state.customerOrderSettings,
}

export const mutations = {
  setCustomOrderItemGroups(state, {customOrderItemGroups}) {
    state.customOrderItemGroups = customOrderItemGroups
  },
  setItemGroups(state, {itemGroups}) {
    state.itemGroups = itemGroups
  },
  setItemTypes(state, {itemTypes}) {
    state.itemTypes = itemTypes
  },
  setCustomOrderItemGroup(state, {customOrderItemGroup}) {
    state.customOrderItemGroups.filter(
      (co) => co.id === customOrderItemGroup.id
    )[0] = customOrderItemGroup
  },
}

export const actions = {
  fetchItemGroups({commit, state}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const itemGroups = await this.$axios.$get(
            '/customOrderItemGroup/get-all'
          )
          resolve({itemGroups})
        },
        formActions.Load,
        'customerOrderSettings'
      )
    })
  },
  fetchCustomOrderItemGroups({commit, state}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const customOrderItemGroups = await this.$axios.$get(
            '/customOrderItemGroup'
          )
          resolve({customOrderItemGroups})
        },
        formActions.Load,
        'customerOrderSettings'
      )
    })
  },
  getItemTypes({commit, state}, {groupID}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const itemTypes = await this.$axios.$get(
            '/customOrderItemGroup/get-item-types/' + groupID
          )
          resolve({itemTypes})
        },
        formActions.Load,
        'customerOrderSettings'
      )
    })
  },
  saveCustomOrderItemGroup({commit}, {customOrderItemGroup}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          await this.$axios.$post(`/customOrderItemGroup`, customOrderItemGroup)

          this.$toast.success(
            this.$i18n
              .t('saved_successfully')
              .replace('{name}', customOrderItemGroup.groupName)
          )
        },
        formActions.Save,
        customOrderItemGroup.groupName
      )
    })
  },
  deleteCustomOrderItemGroup({commit}, {customOrderItemGroup}) {
    console.log(customOrderItemGroup)
    this.$catch(
      async () => {
        await this.$axios.$post(
          `/customOrderItemGroup/delete/` + customOrderItemGroup.id
        )

        console.log(customOrderItemGroup.groupName)
        this.$toast.success(
          this.$i18n
            .t('saved_successfully')
            .replace('{name}', customOrderItemGroup.groupName)
        )
      },
      formActions.Save,
      customOrderItemGroup.groupName
    )
  },
}
