import {formActions} from '@/enums/formActions'

const initState = () => ({
  metals: [],
  itemTypes: [],
  attributes: [],
  metalSubItemVariants: [],
  ringSizeDialogShown: false,
})

export const state = initState

export const getters = {
  getMetals: (state) => state.metals,
  getItemTypes: (state) => state.itemTypes,
  getAttributes: (state) => state.attributes,
}

export const mutations = {
  setMetals(state, {metals}) {
    state.metals = metals
  },
  setItemTypes(state, {itemTypes}) {
    state.itemTypes = itemTypes
  },
  setAttributes(state, {attributes}) {
    state.attributes = attributes
  },
  setMetalSubItemVariants(state, {metalSubItemVariants}) {
    state.metalSubItemVariants = metalSubItemVariants
  },
  setRingSizeDialogShown(state, ringSizeDialogShown) {
    state.ringSizeDialogShown = ringSizeDialogShown
  },
}

export const actions = {
  fetchItemTypesByItemGroupCode({commit}, {itemGroupCode}) {
    return this.$axios.$get(`/ItemTypes/get-item-types-by-group-code/${itemGroupCode}`)
  },
  fetchMetals({commit}) {
    return this.$axios.$get('/Metals/ShownInCatalog').then((metals) => {
      commit('setMetals', {
        metals: metals.map((element) => {
          return {text: element.name, value: element.id}
        }),
      })
    })
  },
  fetchItemTypes({commit}) {
    return this.$axios.$get('/ItemTypes/ShownInCatalog').then((itemTypes) => {
      commit('setItemTypes', {
        itemTypes: itemTypes.map((element) => {
          return {text: element.name, code: element.code, value: element.id}
        }),
      })
    })
  },
  async fetchItemTypeAttributes({commit}, {itemTypeID, showInProductCatalog}) {
    const attributes = await this.$axios.$get(
      `/itemtypes/${itemTypeID}/customattributevalues`
    )
    if (showInProductCatalog) {
      commit('setAttributes', {
        attributes: attributes.filter(
          (x) => x.showInProductCatalog === showInProductCatalog
        ),
      })
    } else {
      commit('setAttributes', {attributes})
    }
  },
  async fetchMetalSubItemVariants({commit}, itemtypeId) {
    await this.$catch(
      async () => {
        const metalSubItemVariants = await this.$axios.$get(
          `/Metals/getMetalSubItemVariants/${itemtypeId}`
        )
        commit('setMetalSubItemVariants', {
          metalSubItemVariants: metalSubItemVariants.map((x) => {
            return {text: x.name, value: x.id}
          }),
        })
      },
      formActions.Load,
      'Metal variants'
    )
  },
  updateAttributes({commit}, attributes) {
    commit('setAttributes', {attributes})
  },
}
