const initState = () => ({
  workflows: [],
})

export const state = initState

export const getters = {
  getWorkflows: (state) => (searchStr) => {
    const regexp = new RegExp(searchStr, 'i')
    return state.workflows.filter(
      (x) => regexp.test(x.name) || regexp.test(x.code)
    )
  },
}

export const mutations = {
  setWorkflows(state, {workflows}) {
    state.workflows = workflows
  },
}

export const actions = {
  /* async fetchWorkflows({commit, state}, {}) {
    return await this.$axios
      .$get('/PriceQuotes/' + priceQuoteId + '/items')
      .then((result) => {
        commit('setworkflows', {workflows: result})
      })
  }, */
}
