import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

export default ({$config}) => {
  // if ($config.googleMapsKey) {
    Vue.use(VueGoogleMaps, {
      load: {
        key: $config.googleMapsKey,
        libraries: 'places',
      },
    })
  // }
}
