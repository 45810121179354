import {jobTypeCodes} from '@/enums/jobTypeCodes'

const initState = () => ({
  jobOrders: [],
  salesOrders: [],
  priceQuotes: [],
  invoices: [],
  joOrderPage: 0,
  totalJobOrders: 0,
  salesOrderPage: 0,
  totalSalesOrders: 0,
  priceQuotePage: 0,
  totalPriceQuotes: 0,
  invoicePage: 0,
  totalInvoices: 0,
  JOLoading: false,
  SOLoading: false,
  PQLoading: false,
  InvoiceLoading: false,
  JOFinished: false,
  SOFinished: false,
  PQFinished: false,
  InvoiceFinished: false,
  messages: [],
  balanceInfo: {},
  metals: [],
  featured: [],
  jobOrderSummaries: [],
  priceQuoteSummaries: [],
  salesOrderSummaries: [],
  invoiceSummaries: [],
})

export const state = initState

export const mutations = {
  setMessages(state, {messages}) {
    state.messages = messages
  },
  setBalanceInfo(state, {balanceInfo}) {
    state.balanceInfo = balanceInfo
  },
  setJobOrders(state, {orders}) {
    if (orders) {
      orders.forEach((jo) => state.jobOrders.push(jo))
    }
  },
  setSalesOrders(state, {orders}) {
    if (orders) {
      orders.forEach((jo) => state.salesOrders.push(jo))
    }
  },
  setPriceQuotes(state, {orders}) {
    if (orders) {
      orders.forEach((jo) => state.priceQuotes.push(jo))
    }
  },
  setInvoices(state, {invoices}) {
    if (invoices) {
      invoices.forEach((jo) => state.invoices.push(jo))
    }
  },
  setMetals(state, {metals}) {
    state.metals = metals
  },
  setFeatured(state, {featured}) {
    state.featured = featured
  },
  setJobOrderSummaries(state, {jobOrderSummaries}) {
    state.jobOrderSummaries = jobOrderSummaries
  },
  setPriceQuoteSummaries(state, {priceQuoteSummaries}) {
    state.priceQuoteSummaries = priceQuoteSummaries
  },
  setSalesOrderSummaries(state, {salesOrderSummaries}) {
    state.salesOrderSummaries = salesOrderSummaries
  },
  setInvoiceSummaries(state, {invoiceSummaries}) {
    state.invoiceSummaries = invoiceSummaries
  },
  setJOLoading(state, {isLoading}) {
    state.JOLoading = isLoading
  },
  setSOLoading(state, {isLoading}) {
    state.SOLoading = isLoading
  },
  setPQOLoading(state, {isLoading}) {
    state.PQLoading = isLoading
  },
  setInvoiceLoading(state, {isLoading}) {
    state.InvoiceLoading = isLoading
  },
  increaseJOPageIndex(state) {
    state.joOrderPage++
  },
  increaseSOPageIndex(state) {
    state.salesOrderPage++
  },
  increasePQPageIndex(state) {
    state.priceQuotePage++
  },
  increaseInvoicePageIndex(state) {
    state.invoicePage++
  },
}

export const actions = {
  async getMessages({commit}) {
    const messages = await this.$axios.$get('/Dashboard/messages')
    commit('setMessages', {messages})
  },
  async getBalanceInfo({commit}) {
    const balanceInfo = await this.$axios.$get('/Dashboard/BalanceInfo')
    commit('setBalanceInfo', {balanceInfo})
  },
  async getJobOrders({commit}, {entityTypeCode, pageSize, page}) {
    const orders = await this.$axios.$get(
      '/Dashboard/Orders/' +
        '?entityTypeCode=' +
        entityTypeCode +
        '&pageSize=' +
        pageSize +
        '&pageNo=' +
        page
    )
    if (orders.length > 0) {
      switch (entityTypeCode) {
        case jobTypeCodes.JobOrder:
          commit('setJobOrders', {orders})
          break
        case jobTypeCodes.SalesOrder:
          commit('setSalesOrders', {orders})
          break
        case jobTypeCodes.PriceQuote:
          commit('setPriceQuotes', {orders})
          break
      }
    } else {
    }
  },
  async getJobOrderSummaries({commit}) {
    const jobOrderSummaries = await this.$axios.$get(
      '/Dashboard/OrdersStatusSummary/' + jobTypeCodes.JobOrder
    )
    commit('setJobOrderSummaries', {jobOrderSummaries})
  },
  async getPriceQuoteSummaries({commit}) {
    const priceQuoteSummaries = await this.$axios.$get(
      '/Dashboard/OrdersStatusSummary/' + jobTypeCodes.PriceQuote
    )
    commit('setPriceQuoteSummaries', {priceQuoteSummaries})
  },
  async getSalesOrderSummaries({commit}) {
    const salesOrderSummaries = await this.$axios.$get(
      '/Dashboard/OrdersStatusSummary/' + jobTypeCodes.SalesOrder
    )
    commit('setSalesOrderSummaries', {salesOrderSummaries})
  },
  async getInvoiceSummaries({commit}) {
    const invoiceSummaries = await this.$axios.$get(
      '/Dashboard/InvoiceStatusSummary'
    )
    commit('setInvoiceSummaries', {invoiceSummaries})
  },
  async getSalesOrders({commit}) {
    const salesOrders = await this.$axios.$get(
      '/Dashboard/Orders/' + jobTypeCodes.SalesOrder
    )
    commit('setSalesOrders', {salesOrders})
  },
  async getPriceQuotes({commit}) {
    const priceQuotes = await this.$axios.$get(
      '/Dashboard/Orders/' + jobTypeCodes.PriceQuote
    )
    commit('setPriceQuotes', {priceQuotes})
  },
  async getInvoices({commit}, {pageSize, page}) {
    const invoices = await this.$axios.$get(
      '/Dashboard/Invoices' + '?pageSize=' + pageSize + '&pageNo=' + page
    )
    commit('setInvoices', {invoices})
  },
  async getMetals({commit}) {
    const metals = await this.$axios.$get('/Dashboard/Metals')
    commit('setMetals', {metals})
  },
  async getFeatured({commit}) {
    const featured = await this.$axios.$get('/Dashboard/Featured')
    commit('setFeatured', {featured})
  },
  async saveDashboard({commit}, {dashboardCards}) {
    const result = await this.$axios.$post(
      '/Dashboard/Settings',
      dashboardCards
    )
  },
  async getDashboard({commit}) {
    return await this.$axios.$get('/Dashboard/Settings')
  },
}
