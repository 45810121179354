import Vue from 'vue'
import Geocoder from '@pderas/vue2-geocoder'
export default ({$config}) => {
  if ($config.googleMapsKey) {
    Vue.use(Geocoder, {
      defaultCountryCode: null,
      defaultLanguage: null,
      defaultMode: 'address',
      googleMapsApiKey: $config.googleMapsKey,
    })
  }
}
