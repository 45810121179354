const initState = () => ({
  creditCards: [],
  creditCardTypes: [],
})

export const state = initState

export const getters = {
  getCreditCardTypes: (state) => () => {
    return state.creditCardTypes
  },
}

export const mutations = {
  addCreditCard(state, {creditCard}) {
    state.creditCards.push(creditCard)
  },
  setCreditCards(state, {creditCards}) {
    state.creditCards = creditCards
  },
  setCreditCardTypes(state, {creditCardTypes}) {
    state.creditCardTypes = creditCardTypes
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  fetchCreditCards({commit}) {
    if (
      this.$auth.loggedIn &&
      this.$auth.user.permissions.find((x) => x === 'CP_PAYMENTS:VIEWP')
    ) {
      return this.$axios.$get('/CreditCard').then((creditCards) => {
        commit('setCreditCards', {creditCards})
      }).catch(ex => {
        console.log(ex)
      })
    }
  },
  fetchCreditCardTypes({commit}) {
    return this.$axios.$get('/CreditCard/types').then((creditCardTypes) => {
      commit('setCreditCardTypes', {
        creditCardTypes: creditCardTypes.map((element) => {
          return {text: element.name, value: element.id}
        })
      })
    })
  },
  async insertSquareCard({commit},{card} ){
    try {
      let response = await this.$axios
        .$post(`/creditcard/insertsquarecard`, card)

      if (response.apiMessages.hasErrors === true) {
        if (response.apiMessages.serverErrors) {
          response.apiMessages.serverErrors.forEach((item) => {
            this.$toast.error(item)
          })
        }
        return null;
      }else {
        return response;
      }

    }catch (e) {
      this.$toast.error("Error while saving square card:")
      console.error('error while inserting square card: ', e)
      return null;
    }
  },
  async insertNMICard({commit}, {card}){
    try {
      return await this.$axios
        .$post(`creditcard/nmi-card`, card);
    }catch (e) {
      if (e.response) {
        this.$toast.error(e.response.data.Message);
      }  else {
        this.$toast.error(e);
      }
      return null
    }

  }
}
