const initState = () => ({
})

export const state = initState

export const actions = {
  async printInvoiceReport({commit}, invoiceID) {
    try{
      if(invoiceID > 0) {
        const response = await this.$axios.get(`/reports/print-invoice/${invoiceID}`, {responseType: 'blob'})
        if (response) {
          const contentDispositionHeader = response.headers['content-disposition']
          const contentType = response.headers['content-type']

          if (contentDispositionHeader && contentType) {
            const fullNameMatch = contentDispositionHeader.match(/filename\*=(.+)$/);
            let fullName = fullNameMatch ? fullNameMatch[1] : null;
            if (fullName) {
              fullName = decodeURIComponent(fullName.replace("UTF-8''", ''));
            }
            const file = new File(
              [response.data],
              fullName,
              {type: contentType}
            );

            const url = window.URL.createObjectURL(file)
            window.open(url, '_blank')

        }
        else{
          this.$toast.error('Error while printing report')
        }
      }
      else {
        this.$toast.show('The invoice that you are trying to print cannot be found')
      }
     }
    }
    catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.$toast.show('The invoice that you are trying to print cannot be found')
        return;
      }
      this.$toast.error('Error while printing report')
    }

  },
}
