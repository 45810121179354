const initState = () => ({
  hasFrontendLayout: false,
})

export const state = initState

export const getters = {
  getHasFrontendLayout: (state) => () => {
    return state.hasFrontendLayout
  },
}

export const mutations = {
  setHasFrontendLayout(state, hasFrontendLayout) {
    state.hasFrontendLayout = hasFrontendLayout
  },
}
