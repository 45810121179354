const initState = () => ({
  customerContactPersons: [],
})

export const state = initState

export const mutations = {
  setCustomerContactPersons(state, {contactPersons}) {
    state.customerContactPersons = contactPersons
  },
}

export const actions = {
  async getCustomerContactPersons({commit}, customerId = undefined) {
    const contactPersons = await this.$axios.$get(
      '/customers/getContactPersons' +
        (customerId ? `?customerId=${customerId}` : '')
    )
    commit('setCustomerContactPersons', {contactPersons})
  },
}
