const initState = () => ({
  decedents: [],
})

export const state = initState

export const mutations = {
  setDecedents(state, {decedents}) {
    state.decedents = decedents
  }
}

export const actions = {
  async getDecedents({commit}, {decedentFilter}){
    return await this.$axios.$get('/decedents' + decedentFilter)
  },
  async getDecedentImages({commit},{decedentId}){
    return await this.$axios.$get(`decedents/${decedentId}/fingerprints`)
  }
}
