import {jobTypeCodes} from '@/enums/jobTypeCodes'
import {formActions} from '@/enums/formActions'
import {getShoppingCartSID} from '@/utils/helpers'

const initState = () => ({
  cartItemCount: 0,
  cartItems: [],
  salesTax: null,
  salesTaxValue: 0,
  salesTaxPercent: 0,
  shippingPrice: 0,
  showCheckout: false,
  subTotal: 0,
  grandTotal: 0,
  customerAddresses: {
    shippingAddress: {},
    billingAddress: {},
    useDifferentShippingAddress: false,
    notes: '',
    poNumber: '',
    customerUpdatedWhen: '',
  },
  cartSideOpen: false,
  discountValue:0
})

export const state = initState

export const getters = {
  getCartItem: (state) => (cartItemId) => {
    return state.cartItems.find((cartItem) => cartItem.id === cartItemId)
  },
  getCartSubtotal(state) {
    let subtotal = 0
    state.cartItems.forEach((item) => {
      subtotal += item.price * item.quantity
    })
    return subtotal
  },
  getCartOrderTotal(state) {
    let orderTotal = 0
    state.cartItems.forEach((item) => {
      orderTotal += item.price * item.quantity
    })
    return orderTotal - (state.discountValue ? state.discountValue : 0)
  },
  getCartSummary(state) {
    const summary = []
    let subTotal = 0

    state.cartItems.map((item) => {
      subTotal += item.itemTypeData.price * item.quantity
    })

    summary.push({name: 'Subtotal', value: subTotal})
    if (state.salesTax && state.salesTax.enabled && state.salesTax.percent > 0)
      summary.push({
        name: 'Sales tax',
        value: (subTotal * state.salesTax.percent) / 100.0,
      })

    summary.push({name: 'Shipping', value: state.shippingPrice})

    return summary
  },
  getTotal(state, getters) {
    return getters.getCartSummary.reduce((total, current) => {
      return total + current.value
    }, 0)
  },
  getCartSideOpen( state ) {
    return state.cartSideOpen
  },
  getSalesTaxValue(state){
    return state.salesTaxValue
  },
  getsalesTaxPercent(state){
    return state.salesTaxPercent
  },
  getDiscountValue(state) {
    return state.discountValue;
  }
}

export const mutations = {
  updateItemQuantity(state, value) {
    state.cartItems.find((item) => item.id === value.id).quantity =
      value.quantity
  },
  setCartItems(state, {cartItems}) {
    state.cartItems = cartItems
  },
  removeCartItem(state, itemID) {
    state.cartItems = state.cartItems.filter((item) => item.id !== itemID)
  },
  setCartItemCount(state) {
    state.cartItemCount = state.cartItems.reduce((total, current) => {
      return total + current.quantity
    }, 0)
  },
  clearCartItemCounter(state) {
    state.cartItems = []
    state.cartItemCount = state.cartItems.reduce((total, current) => {
      return total + current.quantity
    }, 0)
  },
  setSalesTax(state, {salesTax}) {
    state.salesTax = salesTax
  },
  setSalesTaxValue(state, {salesTaxValue}) {
    state.salesTaxValue = salesTaxValue
  },
  setsalesTaxPercent(state, {salesTaxPercent}) {
    state.salesTaxPercent = salesTaxPercent
  },
  setShippingPrice(state, shippingPrice) {
    state.shippingPrice = shippingPrice
  },
  setShowCheckout(state, value) {
    state.showCheckout = value
  },
  setAdditionalInfo(state, value) {
    state.customerAddresses.notes = value.notes
    state.customerAddresses.poNumber = value.PONumber
  },
  setCustomerAddresses(state, {customerAddresses}) {
    state.customerAddresses = customerAddresses
  },
  updateSubTotal(state) {
    let subtotal = 0
    state.cartItems.forEach((item) => (subtotal += item.price * item.quantity))
    state.subTotal = subtotal
  },
  updateGrandTotal(state) {
    let tax =
      state.salesTaxValue > 0  ? state.salesTaxValue :
        (state.salesTax && state.salesTax.enabled && state.salesTax.percent > 0
        ? parseFloat(
            ((state.subTotal * state.salesTax.percent) / 100.0).toFixed(2)
          )
        : 0)

    const totalWithDiscount = getters.getCartOrderTotal(state)
    state.grandTotal =
      totalWithDiscount + (state.shippingPrice ? state.shippingPrice : 0) + tax
  },
  setCartSideOpen(state, data) {
    state.cartSideOpen = data
  },
  emptyCart(state) {
    state.cartItems = []
  },
  setDiscountValue(state, discountValue){
    state.discountValue = discountValue;
  }
}
export const actions = {
  async frontendGetBySessionId({commit}) {
    const shoppingCart = await this.$axios.$get(
      '/shoppingCart/frontendGetBySessionId',
      {
        params: {
          sessionId: getShoppingCartSID(),
        },
      }
    )

    if(shoppingCart && shoppingCart.apiMessages && shoppingCart.apiMessages.hasErrors){
      this.$toast.error(shoppingCart.apiMessages.serverErrors.join('</br>'))
    }else{
      commit('setCartItems', {cartItems: shoppingCart.value.items})
      commit('setSalesTax', {salesTax: shoppingCart.value.salesTax})
      commit('setDiscountValue', shoppingCart.value.discountValue)
      commit('setShippingPrice', shoppingCart.value.shippingPrice)
      commit('setCartItemCount')
      commit('setAdditionalInfo', {
        PONumber: shoppingCart.value?.shoppingCartExtraInfo?.poNumber ?? "",
        notes: shoppingCart.value?.shoppingCartExtraInfo?.notes ?? "",
      })
    }
  },
  async getBySessionId({commit}) {
    const shoppingCart = await this.$axios.$get('/shoppingCart/getBySessionId')
    commit('setCartItems', {cartItems: shoppingCart.items})
    commit('setSalesTax', {salesTax: shoppingCart.salesTax})
    commit('setShippingPrice', shoppingCart.shippingPrice)
    commit('setCartItemCount')
  },
  async fetchDiscountValue({commit}){
    const shoppingCart = await this.$axios.$get(
      '/shoppingCart/getDiscountValue',
      {
        params: {
          sessionId: getShoppingCartSID(),
        },
      }
    )
    if(shoppingCart && shoppingCart.apiMessages && shoppingCart.apiMessages.hasErrors){
      this.$toast.error(shoppingCart.apiMessages.serverErrors.join('</br>'))
    }else{
      commit('setDiscountValue',shoppingCart.value)
      commit('updateSubTotal')
      commit('updateGrandTotal')
    }
  },
  async getCartItems({commit}) {
    const cartItems = await this.$axios.$get(
      '/shoppingCart/shoppingCartItems',
      {
        params: {
          sessionId: getShoppingCartSID(),
        },
      }
    )
    commit('setCartItems', {cartItems})
    commit('setCartItemCount')
    commit('updateSubTotal')
    commit('updateGrandTotal')
  },
  async addToCart({commit, dispatch}, {shoppingCartItem, path}) {
    const addToCartResult = await this.$axios.$post(
      '/ShoppingCart/addToCart',
      shoppingCartItem
    )
    if (addToCartResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator!'
      )
    } else {
      commit('updateSubTotal')
      commit('updateGrandTotal')
      dispatch('getCartItems')
      commit('setCartItemCount')
      if (!path) {
        await this.$router.push('/backoffice/shopping-cart')
      }
    }
    return addToCartResult
  },
  async updateQuantity({commit, dispatch}, updateCartItemData) {
    const updateResult = await this.$axios.$post(
      '/shoppingCart/updateQuantity',
      updateCartItemData
    )
    if (updateResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator!'
      )
    } else {
      dispatch('getCartItems') // TODO create a endpoint for frontend without the tax and shipping maybe?
    }
  },
  async updateQuantityFrontend({commit, dispatch}, updateCartItemData) {
    updateCartItemData.SessionId = getShoppingCartSID()
    const updateResult = await this.$axios.$post(
      '/shoppingCart/updateQuantity',
      updateCartItemData
    )
    if (updateResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact customer support!!'
      )
    } else {
      commit('updateItemQuantity', updateCartItemData)
      dispatch('frontendGetBySessionId')
    }
  },
  async deleteCartItemFrontend({commit, dispatch}, id) {
    const updateResult = await this.$axios.$delete(
      `/shoppingCart/deleteCartItem/${id}`,
      {
        params: {
          sessionId: getShoppingCartSID(),
        },
      }
    )
    commit('removeCartItem', id)
    dispatch('frontendGetBySessionId')
  },
  async clearCartFrontend({commit, dispatch}) {
    const clearResult = await this.$axios.$post(
      `/shoppingCart/clear-cart`,
      undefined,
      {
        params: {
          sessionId: getShoppingCartSID(),
        },
      }
    )
    commit('emptyCart')
    dispatch('frontendGetBySessionId')
    return clearResult
  },
  async deleteCartItem({commit, dispatch}, id) {
    const updateResult = await this.$axios.$delete(
      `/shoppingCart/deleteCartItem/${id}`
    )
    dispatch('getCartItems')
  },

  async submitOrder({commit}, {inputData}) {
    const submitOrderResult = await this.$axios.$post(
      '/shoppingCart/submitOrder',
      inputData
    )
    if (submitOrderResult.hasErrors) {
      if (
        submitOrderResult.errorMessages.some((x) => x.key === 'jobInsertError')
      ) {
        this.$toast.error(
          submitOrderResult.errorMessages
            .filter((x) => x.key === 'jobInsertError')
            .map((x) => {
              return x.value
            })
            .join('<br>')
        )
      } else {
        this.$toast.error(
          'Something went wrong! Please check the portal logs for more information!'
        )
      }
    } else {
      this.$toast.success(
        `${submitOrderResult.value.order_code} - created successfully!`
      )
      commit('clearCartItemCounter')
      switch (submitOrderResult.value.jobTypeCode) {
        case jobTypeCodes.JobOrder:
          this.$router.push({
            path: '/backoffice/status-tracking/job-orders',
            query: {tab: 'new'},
          })
          break
        case jobTypeCodes.SalesOrder:
          this.$router.push({
            path: '/backoffice/status-tracking/sales-orders',
            query: {tab: 'new'},
          })
          break
        case jobTypeCodes.PriceQuote:
          this.$router.push({
            path: '/backoffice/status-tracking/quotes',
            query: {tab: 'new'},
          })
          break
      }
    }
  },
  async getCustomerAddresses({commit}) {
    const addresses = await this.$axios.$get('/customers/getCustomerAddresses')
    commit('setCustomerAddresses', {customerAddresses: addresses})
  },
  getSalesTax({commit}, {customerId, billingStateId, shippingStateId}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const shoppingCartSalesTax = await this.$axios.$post(
            `/shoppingCart/get-sales-tax`,
            {customerId, billingStateId, shippingStateId}
          )
          commit('setSalesTax', {salesTax: shoppingCartSalesTax})
          commit('updateGrandTotal')
          resolve()
        },
        formActions.Load,
        'Sales tax'
      )
    })
  },
  async updateSalesTax({commit}, inputData) {
    inputData.SessionId = getShoppingCartSID();
    return await this.$axios.$post(
      '/shoppingCart/update-tax',
      inputData
    )
  },
  setCartSideOpen({commit, dispatch}, cartSideOpen) {
    commit('setCartSideOpen', cartSideOpen)
  },
  async updateShoppingCartExtraInfo({commit}, extraInfo){
    try {
      extraInfo.sessionId = getShoppingCartSID();
      const updateResult = await this.$axios.$post(
        `/shoppingCart/update-extra-info`,
        extraInfo
        );

      if(updateResult && updateResult.apiMessages && updateResult.apiMessages.hasErrors){
        this.$toast.error(updateResult.apiMessages.serverErrors.join('</br>'))
      }
    }
    catch (e) {
      this.$toast.error("Error while updating shopping cart!");
    }


  }
}
