export default ({$config}) => {
  if ($config.smartLookId) {
    window.smartlook ||
      (function (d) {
        const o = (window.smartlook = function () {
          o.api.push(arguments)
        })
        const h = d.getElementsByTagName('head')[0]
        const c = d.createElement('script')
        o.api = []
        c.async = true
        c.type = 'text/javascript'
        c.charset = 'utf-8'
        c.src = 'https://rec.smartlook.com/recorder.js'
        h.appendChild(c)
      })(document)
    window.smartlook('init', $config.smartLookId)
    window.smartlook('record', { numbers: true, forms: true})
  }
}
