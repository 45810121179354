const initState = () => ({
  estimateData: {
    services: [],
    priorities: [],
    treatments: [],
    metals: [],
    attributes: [],
    currencySymbol: null,
    masterAlloyPrices: null,
  },
  measureUnitConversionValue: 1, // g,
  formLoading: false,
})

export const state = initState

export const getters = {
  getEstimateData: (state) => () => state.estimateData,
}

export const mutations = {
  setEstimateData(state, {estimateData}) {
    state.estimateData = estimateData
  },

  setMeasureUnitConversionValue(state, {measureUnitConversionValue}) {
    state.measureUnitConversionValue = measureUnitConversionValue
  },
  setFormLoading(state, {formLoading}) {
    state.formLoading = formLoading
  },
}

export const actions = {
  async fetchEstimateData({commit, state}) {
    return await this.$axios.$get('/Estimate/data').then((response) => {
      const lEstimateData = {
        services: response.serviceTypeGroups,
        treatments: response.treatments,
        priorities: response.priorities,
        metals: response.metals,
        currencySymbol: response.currencySymbol,
        masterAlloyPrices: response.masterAlloyPrices,
        attributes: response.attributes
      }
      commit('setEstimateData', {estimateData: lEstimateData})
      //    this.ShippingMethods = response.data.Value.ShippingMethods
    })
  },

  async saveJobNote({commit, state}, {note, jobID}) {
    return await this.$axios.$post(`/JobOrders/notes`, {
      note,
      jobOrderID: jobID,
    })
  },

  async getMeasureUnitConversions({commit}, {muSymbolSource, muSymbolTarget}) {
    const MUConversionValue = await this.$axios.$get(
      `/Metals/measureUnitConversions/${muSymbolSource}/${muSymbolTarget}`
    )
    commit('setMeasureUnitConversionValue', {
      measureUnitConversionValue: MUConversionValue,
    })
  },
}
