const initState = () => ({
  customerTypes: [],
})

export const state = initState

export const getters = {
  getCustomerTypes: (state) => () => {
    return state.customerTypes
  },
}

export const mutations = {
  setCustomerTypes(state, {customerTypes}) {
    state.customerTypes = customerTypes
  },
}

export const actions = {
  fetchCustomerTypes({commit}) {
    return this.$axios.$get('CustomerTypes').then((customerTypes) => {
      commit('setCustomerTypes', {
        customerTypes: customerTypes.map((element) => {
          return {text: element.name, value: element.id}
        }),
      })
    })
  },
}
