const initState = () => ({
  currentPriceQuote: null,
  priceQuoteItems: [],
  priceQuotes: [],
  page: 1,
  totalQuotes: 0,
  options: {},
})

export const state = initState

export const getters = {
  getPriceQuoteItems: (state) => () => state.priceQuoteItems,
  getPriceQuote: (state) => () => state.currentPriceQuote,
}

export const mutations = {
  setPriceQuoteItems(state, {priceQuoteItems}) {
    state.priceQuoteItems = priceQuoteItems
  },
  setPriceQuote(state, {priceQuote}) {
    state.currentPriceQuote = priceQuote
  },
  setPriceQuotes(state, {priceQuotes}) {
    state.priceQuotes = priceQuotes
  },
  setTotalQuotes(state, {totalQuotes}) {
    state.totalQuotes = totalQuotes
  },
  setOptions(state, {options}) {
    state.options = options
  },
}

export const actions = {
  async fetchPriceQuoteItems({commit, state}, {priceQuoteId}) {
    return await this.$axios
      .$get('/PriceQuotes/' + priceQuoteId + '/items')
      .then((result) => {
        commit('setPriceQuoteItems', {priceQuoteItems: result})
      })
  },
  async fetchPriceQuotes({commit, state}) {
    const {page, itemsPerPage} = state.options
    const result = await this.$axios.$get(
      '/PriceQuotes/?pageSize=' +
        (itemsPerPage ?? 10) +
        '&pageNo=' +
        (page ?? 1)
    )
    commit('setPriceQuotes', {priceQuotes: result.items})
    commit('setTotalQuotes', {totalQuotes: result.pagingData.itemCount})
  },
}
